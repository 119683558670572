const guide = {
    start_building: 'ابدأ البناء',
    get_started: 'ابدأ',
    categories: {
        featured: 'شائع ومناسب لك',
        Traditional: 'تطبيق ويب تقليدي',
        SPA: 'تطبيق صفحة واحدة',
        Native: 'تطبيق محلي',
        MachineToMachine: 'ماكينة إلى ماكينة',
        Protected: 'تكامل غير محمي',
        ThirdParty: 'تطبيق طرف ثالث',
        /** UNTRANSLATED */
        SAML: 'SAML',
    },
    filter: {
        title: 'تصفية الإطار',
        placeholder: 'البحث عن إطار',
    },
    checkout_tutorial: 'تحقق من درس {{name}}',
    do_not_need_tutorial: 'إذا لم تحتاج إلى درس، يمكنك المتابعة بدون دليل إطار',
    finish_and_done: 'انتهاء وتمام',
    cannot_find_guide: 'لا يمكن العثور على الدليل الخاص بك؟',
    describe_guide_looking_for: 'صف الدليل الذي تبحث عنه',
    request_guide_successfully: 'تم إرسال طلبك بنجاح. شكرًا لك!',
    app: {
        select_framework_or_tutorial: 'اختر إطارًا أو درسًا',
        guide_modal_title: 'ابدأ مع SDK والدروس',
        modal_subtitle: 'ابدأ عملية تطوير التطبيق الخاص بك باستخدام SDK والدروس المُعدة مسبقًا.',
        select_a_framework: 'اختر إطارًا',
        continue_without_framework: 'إنشاء تطبيق بدون إطار',
        describe_guide_looking_for_placeholder: 'مثال: أرغب في دمج Logto في تطبيق Angular الخاص بي.',
    },
    api: {
        modal_title: 'ابدأ مع الدروس',
        modal_subtitle: 'ابدأ عملية تطوير التطبيق الخاص بك باستخدام الدروس المُعدة مسبقًا.',
        select_a_tutorial: 'اختر درسًا',
        continue_without_tutorial: 'المتابعة بدون درس',
        describe_guide_looking_for_placeholder: 'مثال: أرغب في حماية واجهة برمجة التطبيقات الخاصة بي باستخدام deno.',
    },
};
export default Object.freeze(guide);
