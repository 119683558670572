const application = {
    invalid_type: 'Solo las aplicaciones de máquina a máquina pueden tener roles asociados.',
    role_exists: 'La identificación del rol {{roleId}} ya se ha agregado a esta aplicación.',
    invalid_role_type: 'No se puede asignar un rol de tipo usuario a una aplicación de máquina a máquina.',
    invalid_third_party_application_type: 'Solo las aplicaciones web tradicionales pueden ser marcadas como una aplicación de terceros.',
    third_party_application_only: 'La función solo está disponible para aplicaciones de terceros.',
    user_consent_scopes_not_found: 'Ámbitos de consentimiento de usuario no válidos.',
    consent_management_api_scopes_not_allowed: 'Los ámbitos de la API de administración no están permitidos.',
    protected_app_metadata_is_required: 'Se requiere metadatos de aplicación protegida.',
    protected_app_not_configured: 'El proveedor de aplicación protegida no está configurado. Esta función no está disponible para la versión de código abierto.',
    cloudflare_unknown_error: 'Se produjo un error desconocido al solicitar la API de Cloudflare',
    protected_application_only: 'La función solo está disponible para aplicaciones protegidas.',
    protected_application_misconfigured: 'La aplicación protegida está mal configurada.',
    protected_application_subdomain_exists: 'El subdominio de la aplicación protegida ya está en uso.',
    invalid_subdomain: 'Subdominio no válido.',
    custom_domain_not_found: 'Dominio personalizado no encontrado.',
    should_delete_custom_domains_first: 'Debe eliminar primero los dominios personalizados.',
    no_legacy_secret_found: 'La aplicación no tiene un secreto heredado.',
    secret_name_exists: 'El nombre del secreto ya existe.',
    saml: {
        /** UNTRANSLATED */
        use_saml_app_api: 'Use `[METHOD] /saml-applications(/.*)?` API to operate SAML app.',
        /** UNTRANSLATED */
        saml_application_only: 'The API is only available for SAML applications.',
        /** UNTRANSLATED */
        acs_url_binding_not_supported: 'Only HTTP-POST binding is supported for receiving SAML assertions.',
        /** UNTRANSLATED */
        can_not_delete_active_secret: 'Can not delete the active secret.',
        /** UNTRANSLATED */
        no_active_secret: 'No active secret found.',
        /** UNTRANSLATED */
        entity_id_required: 'Entity ID is required to generate metadata.',
        /** UNTRANSLATED */
        name_id_format_required: 'Name ID format is required.',
        /** UNTRANSLATED */
        unsupported_name_id_format: 'Unsupported name ID format.',
        /** UNTRANSLATED */
        missing_email_address: 'User does not have an email address.',
        /** UNTRANSLATED */
        email_address_unverified: 'User email address is not verified.',
        /** UNTRANSLATED */
        invalid_certificate_pem_format: 'Invalid PEM certificate format',
        /** UNTRANSLATED */
        acs_url_required: 'Assertion Consumer Service URL is required.',
        /** UNTRANSLATED */
        private_key_required: 'Private key is required.',
        /** UNTRANSLATED */
        certificate_required: 'Certificate is required.',
        /** UNTRANSLATED */
        invalid_saml_request: 'Invalid SAML authentication request.',
        /** UNTRANSLATED */
        auth_request_issuer_not_match: 'The issuer of the SAML authentication request mismatch with service provider entity ID.',
        /** UNTRANSLATED */
        sp_initiated_saml_sso_session_not_found_in_cookies: 'Service provider initiated SAML SSO session ID not found in cookies.',
        /** UNTRANSLATED */
        sp_initiated_saml_sso_session_not_found: 'Service provider initiated SAML SSO session not found.',
        /** UNTRANSLATED */
        state_mismatch: '`state` mismatch.',
    },
};
export default Object.freeze(application);
