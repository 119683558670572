const application = {
    invalid_type: 'Nur Maschinen-zu-Maschinen-Anwendungen können Rollen haben.',
    role_exists: 'Die Rolle mit der ID {{roleId}} wurde bereits dieser Anwendung hinzugefügt.',
    invalid_role_type: 'Es ist nicht möglich, einer Maschinen-zu-Maschinen-Anwendung eine Benutzertyp-Rolle zuzuweisen.',
    invalid_third_party_application_type: 'Nur traditionelle Webanwendungen können als Drittanbieter-App markiert werden.',
    third_party_application_only: 'Das Feature ist nur für Drittanbieter-Anwendungen verfügbar.',
    user_consent_scopes_not_found: 'Ungültige Benutzerzustimmungsbereiche.',
    consent_management_api_scopes_not_allowed: 'Management API scopes are not allowed.',
    protected_app_metadata_is_required: 'Geschützte App-Metadaten sind erforderlich.',
    protected_app_not_configured: 'Geschützter App-Anbieter ist nicht konfiguriert. Dieses Feature ist in der Open-Source-Version nicht verfügbar.',
    cloudflare_unknown_error: 'Fehler unbekannt beim Abrufen der Cloudflare API',
    protected_application_only: 'Das Feature ist nur für geschützte Anwendungen verfügbar.',
    protected_application_misconfigured: 'Geschützte Anwendung falsch konfiguriert.',
    protected_application_subdomain_exists: 'Die Subdomain der geschützten Anwendung wird bereits verwendet.',
    invalid_subdomain: 'Ungültige Subdomain.',
    custom_domain_not_found: 'Benutzerdefinierte Domain nicht gefunden.',
    should_delete_custom_domains_first: 'Benutzerdefinierte Domains sollten zuerst gelöscht werden.',
    no_legacy_secret_found: 'Die Anwendung hat kein altes Geheimnis.',
    secret_name_exists: 'Geheimnisname existiert bereits.',
    saml: {
        /** UNTRANSLATED */
        use_saml_app_api: 'Use `[METHOD] /saml-applications(/.*)?` API to operate SAML app.',
        /** UNTRANSLATED */
        saml_application_only: 'The API is only available for SAML applications.',
        /** UNTRANSLATED */
        acs_url_binding_not_supported: 'Only HTTP-POST binding is supported for receiving SAML assertions.',
        /** UNTRANSLATED */
        can_not_delete_active_secret: 'Can not delete the active secret.',
        /** UNTRANSLATED */
        no_active_secret: 'No active secret found.',
        /** UNTRANSLATED */
        entity_id_required: 'Entity ID is required to generate metadata.',
        /** UNTRANSLATED */
        name_id_format_required: 'Name ID format is required.',
        /** UNTRANSLATED */
        unsupported_name_id_format: 'Unsupported name ID format.',
        /** UNTRANSLATED */
        missing_email_address: 'User does not have an email address.',
        /** UNTRANSLATED */
        email_address_unverified: 'User email address is not verified.',
        /** UNTRANSLATED */
        invalid_certificate_pem_format: 'Invalid PEM certificate format',
        /** UNTRANSLATED */
        acs_url_required: 'Assertion Consumer Service URL is required.',
        /** UNTRANSLATED */
        private_key_required: 'Private key is required.',
        /** UNTRANSLATED */
        certificate_required: 'Certificate is required.',
        /** UNTRANSLATED */
        invalid_saml_request: 'Invalid SAML authentication request.',
        /** UNTRANSLATED */
        auth_request_issuer_not_match: 'The issuer of the SAML authentication request mismatch with service provider entity ID.',
        /** UNTRANSLATED */
        sp_initiated_saml_sso_session_not_found_in_cookies: 'Service provider initiated SAML SSO session ID not found in cookies.',
        /** UNTRANSLATED */
        sp_initiated_saml_sso_session_not_found: 'Service provider initiated SAML SSO session not found.',
        /** UNTRANSLATED */
        state_mismatch: '`state` mismatch.',
    },
};
export default Object.freeze(application);
