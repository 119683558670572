const general = {
    placeholder: 'プレースホルダー',
    submit: '送信',
    skip: 'スキップ',
    next: '次へ',
    back: '戻る',
    retry: 'もう一度試す',
    done: '完了',
    search: '検索',
    search_placeholder: '検索',
    clear_result: '検索結果をクリアする',
    save: '保存',
    save_changes: '変更を保存する',
    saved: '保存しました',
    discard: '破棄する',
    /** UNTRANSLATED */
    download: 'Download',
    loading: '読み込み中...',
    redirecting: 'リダイレクト中...',
    add: '追加',
    added: '追加されました',
    cancel: 'キャンセル',
    confirm: '確認',
    check_out: 'チェックアウト',
    create: '作成する',
    set_up: '設定する',
    customize: 'カスタマイズする',
    enable: '有効にする',
    reminder: 'リマインダー',
    edit: '編集',
    delete: '削除',
    deleted: '削除済',
    /** UNTRANSLATED */
    activate: 'Activate',
    /** UNTRANSLATED */
    deactivate: 'Deactivate',
    more_options: 'その他のオプション',
    close: '閉じる',
    copy: 'コピーする',
    copying: 'コピー中',
    copied: 'コピーされました',
    required: '必須',
    add_another: 'もう一つ追加',
    create_another: 'もう一つ作成する',
    deletion_confirmation: '{{title}}を削除してよろしいですか？',
    settings_nav: '設定',
    unsaved_changes_warning: '変更を加えました。このページを離れてもよろしいですか？',
    leave_page: 'ページを離れる',
    stay_on_page: 'ページにとどまる',
    type_to_search: '検索する',
    got_it: '了解',
    continue: '続ける',
    page_info: '{{total}}件中{{min}}件〜{{max}}件を表示',
    learn_more: '詳しく見る',
    tab_error_one: '{{count, number}} 件のエラー',
    tab_error_other: '{{count}} 件のエラーがあります',
    skip_for_now: '今回はスキップする',
    remove: '削除する',
    visit: '訪問する',
    join: '参加する',
    try_now: '今すぐ試す',
    multiple_form_field: '(複数)',
    demo: 'デモ',
    unnamed: '名前がありません',
    view: '表示',
    open: '開く',
    hide: '非表示',
    unknown_error: '不明なエラーが発生しました。後で再試行してください。',
    select: '選択する',
    contact_us_action: 'お問い合わせ',
    description: '説明',
    name: '名前',
    add_field: '{{field}}を追加',
    create_field: '{{field}}を作成',
    edit_field: '{{field}}を編集',
    delete_field: '{{field}}を削除',
    coming_soon: '近日公開予定',
    or: 'または',
    reset: 'リセット',
    generate: '生成する',
};
export default Object.freeze(general);
