const guide = {
    start_building: '开始构建',
    get_started: '立即开始',
    categories: {
        featured: '推荐热门开发框架',
        Traditional: '传统网页应用',
        SPA: '单页应用',
        Native: '原生应用',
        MachineToMachine: 'Machine-to-machine',
        Protected: '非 SDK 集成',
        ThirdParty: '第三方应用',
        /** UNTRANSLATED */
        SAML: 'SAML',
    },
    filter: {
        title: '筛选框架',
        placeholder: '搜索框架',
    },
    checkout_tutorial: '查看 {{name}} 教程',
    do_not_need_tutorial: '如果你现在不需要教程，可以点击右侧按钮继续创建应用',
    finish_and_done: '完成',
    cannot_find_guide: '找不到你想要的教程？',
    describe_guide_looking_for: '描述你正在寻找的教程',
    request_guide_successfully: '请求已成功提交，谢谢！',
    app: {
        select_framework_or_tutorial: '选择一个框架或教程',
        guide_modal_title: '从 SDK 和指南开始',
        modal_subtitle: '使用我们提供的 SDK 和集成教程加速你的应用开发过程。',
        select_a_framework: '选择一个框架',
        continue_without_framework: '跳过教程直接创建应用',
        describe_guide_looking_for_placeholder: '例如：我想将 Logto 集成到我的 Angular 应用程序中。',
    },
    api: {
        modal_title: '首先从教程开始',
        modal_subtitle: '使用我们提供的教程来加速你的应用程序开发过程。',
        select_a_tutorial: '选择一个教程',
        continue_without_tutorial: '跳过教程直接创建 API 资源',
        describe_guide_looking_for_placeholder: '例如：我想使用 deno 框架为我的 API 鉴权。',
    },
};
export default Object.freeze(guide);
