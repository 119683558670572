const usage = {
    /** UNTRANSLATED */
    status_active: 'In use',
    /** UNTRANSLATED */
    status_inactive: 'Not in use',
    /** UNTRANSLATED */
    limited_status_quota_description: '(First {{quota}} included)',
    /** UNTRANSLATED */
    unlimited_status_quota_description: '(Included)',
    /** UNTRANSLATED */
    disabled_status_quota_description: '(Not included)',
    /** UNTRANSLATED */
    usage_description_with_unlimited_quota: '{{usage}}<span> (Unlimited)</span>',
    /** UNTRANSLATED */
    usage_description_with_limited_quota: '{{usage}}<span> (First {{basicQuota}} included)</span>',
    /** UNTRANSLATED */
    usage_description_without_quota: '{{usage}}<span> (Not included)</span>',
    mau: {
        title: 'MAU',
        tooltip: 'MAU 是指在一个计费周期内与 Logto 交换过至少一个令牌的唯一用户。Pro 计划无限制。<a>了解更多</a>',
        /** UNTRANSLATED */
        tooltip_for_enterprise: 'A MAU is a unique user who has exchanged at least one token with Logto within a billing cycle. Unlimited for the Enterprise Plan.',
    },
    organizations: {
        title: '组织',
        tooltip: '附加功能，每月固定费用为 ${{price, number}} 。组织的数量或其活动水平不影响价格。',
        /** UNTRANSLATED */
        description_for_enterprise: '(Included)',
        /** UNTRANSLATED */
        tooltip_for_enterprise: 'Inclusion depends on your plan. If the organization feature isn’t in your initial contract, it will be added to your bill when you activate it. The add-on costs ${{price, number}}/month, regardless of the number of organizations or their activity.',
        /** UNTRANSLATED */
        tooltip_for_enterprise_with_numbered_basic_quota: 'Your plan includes the first {{basicQuota}} organizations for free. If you need more, you can add them with the organization add-on at a flat rate of ${{price, number}} per month, regardless of the number of organizations or their activity level.',
    },
    mfa: {
        title: 'MFA',
        tooltip: '附加功能，每月固定费用为 ${{price, number}} 。认证因素的数量不影响价格。',
        /** UNTRANSLATED */
        tooltip_for_enterprise: 'Inclusion depends on your plan. If the MFA feature isn’t in your initial contract, it will be added to your bill when you activate it. The add-on costs ${{price, number}}/month, regardless of the number of authentication factors used.',
    },
    enterprise_sso: {
        title: '企业 SSO',
        tooltip: '附加功能，每个 SSO 连接每月价格为 ${{price, number}} 。',
        /** UNTRANSLATED */
        tooltip_for_enterprise: 'Add-on feature with a price of ${{price, number}} per SSO connection per month. The first {{basicQuota}} SSO are included and free to use in your contract-based plan.',
    },
    api_resources: {
        title: 'API 资源',
        tooltip: '附加功能，每个资源每月价格为 ${{price, number}} 。前三个 API 资源免费。',
        /** UNTRANSLATED */
        tooltip_for_enterprise: 'The first {{basicQuota}} API resources are included and free to use in your contract-based plan. If you need more, ${{price, number}} per API resource per month.',
    },
    machine_to_machine: {
        title: '机器对机器',
        tooltip: '附加功能，每个应用每月价格为 ${{price, number}} 。第一个机器对机器应用免费。',
        /** UNTRANSLATED */
        tooltip_for_enterprise: 'The first {{basicQuota}} machine-to-machine app is free to use in your contract-based plan. If you need more, ${{price, number}} per app per month.',
    },
    tenant_members: {
        title: '租户成员',
        tooltip: '附加功能，每个成员每月价格为 ${{price, number}} 。前三个租户成员免费。',
        /** UNTRANSLATED */
        tooltip_for_enterprise: 'The first {{basicQuota}} tenant members are included and free to use in your contract-based plan. If you need more, ${{price, number}} per tenant member per month.',
    },
    tokens: {
        title: '令牌',
        tooltip: '附加功能，每 {{tokenLimit}} 个令牌价格为 ${{price, number}} 。首 {{basicQuota}} 个令牌包含在内。',
        /** UNTRANSLATED */
        tooltip_for_enterprise: 'The first {{basicQuota}} tokens is included and free to use in your contract-based plan. If you need more, ${{price, number}} per {{tokenLimit}} tokens per month.',
    },
    hooks: {
        title: '钩子',
        tooltip: '附加功能，每个钩子价格为 ${{price, number}} 。前十个钩子包含在内。',
        /** UNTRANSLATED */
        tooltip_for_enterprise: 'The first {{basicQuota}} hooks are included and free to use in your contract-based plan. If you need more, ${{price, number}} per hook per month.',
    },
    pricing: {
        add_on_changes_in_current_cycle_notice: '如果你在当前计费周期内进行任何更改，你的下一个账单可能会在更改后的第一个月稍高。它将是 ${{price, number}} 基础价格加上当前周期中的未计费使用量的附加费用，以及下一个周期的全额费用。<a>了解更多</a>',
    },
};
export default Object.freeze(usage);
