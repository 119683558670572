const application = {
    invalid_type: 'Только приложения типа "от машины к машине" могут иметь связанные роли.',
    role_exists: 'Роль с идентификатором {{roleId}} уже добавлена в это приложение.',
    invalid_role_type: 'Невозможно назначить роль типа "пользователь" для приложения типа "от машины к машине".',
    invalid_third_party_application_type: 'Только традиционные веб-приложения могут быть помечены как приложения сторонних разработчиков.',
    third_party_application_only: 'Эта функция доступна только для приложений сторонних разработчиков.',
    user_consent_scopes_not_found: 'Недействительные области согласия пользователя.',
    consent_management_api_scopes_not_allowed: 'API-области управления не разрешены.',
    protected_app_metadata_is_required: 'Требуется защищенная метаданные приложения.',
    protected_app_not_configured: 'Поставщик защищенного приложения не настроен. Эта функция недоступна для версии с открытым исходным кодом.',
    cloudflare_unknown_error: 'Получена неизвестная ошибка при запросе к API Cloudflare',
    protected_application_only: 'Эта функция доступна только для защищенных приложений.',
    protected_application_misconfigured: 'Защищенное приложение настроено неверно.',
    protected_application_subdomain_exists: 'Поддомен защищенного приложения уже используется.',
    invalid_subdomain: 'Недопустимый поддомен.',
    custom_domain_not_found: 'Пользовательский домен не найден.',
    should_delete_custom_domains_first: 'Сначала следует удалить пользоватские домены.',
    no_legacy_secret_found: 'Приложение не имеет устаревшего секрета.',
    secret_name_exists: 'Имя секрета уже существует.',
    saml: {
        /** UNTRANSLATED */
        use_saml_app_api: 'Use `[METHOD] /saml-applications(/.*)?` API to operate SAML app.',
        /** UNTRANSLATED */
        saml_application_only: 'The API is only available for SAML applications.',
        /** UNTRANSLATED */
        acs_url_binding_not_supported: 'Only HTTP-POST binding is supported for receiving SAML assertions.',
        /** UNTRANSLATED */
        can_not_delete_active_secret: 'Can not delete the active secret.',
        /** UNTRANSLATED */
        no_active_secret: 'No active secret found.',
        /** UNTRANSLATED */
        entity_id_required: 'Entity ID is required to generate metadata.',
        /** UNTRANSLATED */
        name_id_format_required: 'Name ID format is required.',
        /** UNTRANSLATED */
        unsupported_name_id_format: 'Unsupported name ID format.',
        /** UNTRANSLATED */
        missing_email_address: 'User does not have an email address.',
        /** UNTRANSLATED */
        email_address_unverified: 'User email address is not verified.',
        /** UNTRANSLATED */
        invalid_certificate_pem_format: 'Invalid PEM certificate format',
        /** UNTRANSLATED */
        acs_url_required: 'Assertion Consumer Service URL is required.',
        /** UNTRANSLATED */
        private_key_required: 'Private key is required.',
        /** UNTRANSLATED */
        certificate_required: 'Certificate is required.',
        /** UNTRANSLATED */
        invalid_saml_request: 'Invalid SAML authentication request.',
        /** UNTRANSLATED */
        auth_request_issuer_not_match: 'The issuer of the SAML authentication request mismatch with service provider entity ID.',
        /** UNTRANSLATED */
        sp_initiated_saml_sso_session_not_found_in_cookies: 'Service provider initiated SAML SSO session ID not found in cookies.',
        /** UNTRANSLATED */
        sp_initiated_saml_sso_session_not_found: 'Service provider initiated SAML SSO session not found.',
        /** UNTRANSLATED */
        state_mismatch: '`state` mismatch.',
    },
};
export default Object.freeze(application);
